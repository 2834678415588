


<template>
  <div id="app">
    <!-- <router-view :key='$route.fullPath'></router-view> -->

    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
   
    };
  },
  created() {
  },
  methods: {

  },

};
</script>

