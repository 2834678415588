import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'



Vue.use(VueRouter)
// pc端
const routesP = [{
    path: '/rulePage',
    name: 'rulePage',
    component: () => import('@/views/pc/rulePage.vue'),

  },
  {
    path: '/requestDeletion',
    name: 'requestDeletion',
    component: () => import('@/views/pc/requestDeletion.vue'),

  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/pc/service.vue'),

  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('@/views/pc/Privacy.vue'),
    meta: {
      title: "Privacy Policy"
    }

  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/pc/index.vue'),
    meta: {
      title: "Whis"
    }

  },

  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/pc/about.vue'),

  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/pc/pay.vue'),
  },

  {
    path: '/redEvenpol',
    name: 'redEvenpol',
    component: () => import('@/views/pc/redEvenpol.vue'),
  },
  {
    path: '/accountPage',
    name: 'accountPage',
    component: () => import('@/views/pc/accountPage.vue'),
  },
  {
    path: '/reCharge',
    name: 'reCharge',
    component: () => import('@/views/pc/reCharge.vue'),
  },
  {
    path: '/charmPage',
    name: 'charmPage',
    component: () => import('@/views/pc/charmPage.vue'),
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/pc/wallet.vue'),
  },



  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/pc/service.vue'),
    meta: {
      title: "User Agreement"
    }

  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('@/views/pc/Privacy.vue'),
    meta: {
      title: "Privacy Policy"
    }

  },
  {
    path: '/requestDeletion',
    name: 'requestDeletion',
    component: () => import('@/views/pc/requestDeletion.vue'),
    meta: {
      title: "Privacy Policy"
    }

  },
]
//移动端
const routesM = [{
    path: '/appList',
    name: 'appList',
    component: () => import('@/views/mobile/appList.vue'),
  },
  {
    path: '/appRule',
    name: 'appRule',
    component: () => import('@/views/mobile/appRule.vue'),
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/mobile/pay.vue'),
  },
  {
    path: '/ruleList',
    name: 'ruleList',
    component: () => import('@/views/mobile/ruleList.vue'),
  },
  {
    path: '/redEvenpol',
    name: 'redEvenpol',
    component: () => import('@/views/mobile/redEvenpol.vue'),
  },
  {
    path: '/accountPage',
    name: 'accountPage',
    component: () => import('@/views/mobile/accountPage.vue'),
  },
  {
    path: '/reCharge',
    name: 'reCharge',
    component: () => import('@/views/mobile/reCharge.vue'),
  },
  {
    path: '/charmPage',
    name: 'charmPage',
    component: () => import('@/views/mobile/charmPage.vue'),
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/mobile/wallet.vue'),
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import('@/views/mobile/rule.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/mobile/login.vue'),
  },
  {
    path: '/infoPage',
    name: 'infoPage',
    component: () => import('@/views/mobile/info.vue'),
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/pc/service.vue'),
    meta: {
      title: "User Agreement"
    }

  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: () => import('@/views/pc/Privacy.vue'),
    meta: {
      title: "Privacy Policy"
    }

  },
  {
    path: '/requestDeletion',
    name: 'requestDeletion',
    component: () => import('@/views/pc/requestDeletion.vue'),
    meta: {
      title: "Privacy Policy"
    }

  },
  {
    path: '/abouts',
    name: 'abouts',
    component: () => import('@/views/mobile/abouts.vue'),

  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/mobile/index.vue'),
    meta: {
      title: "Whis"
    }

  }
]


const userAgent = navigator.userAgent;
let ua = navigator.userAgent;
let isSafari = ua.indexOf("Safari") != -1 && ua.indexOf("Version") != -1;
let isIphone = ua.indexOf("iPhone") != -1 && ua.indexOf("Version") != -1
let isIPad = isSafari && !isIphone && 'ontouchend' in document;
var routes = [];
if ((navigator.userAgent.match(/(iPhone|iPod|iPad|Android|Mobile|webOS|BlackBerry|IEMobile|Opera Mini|Windows Phone|Symbian|Kindle|Tablet|KFAPWI|Silk|Android 2.3|Android 4.0|Android 4.1|Android 4.2|Android 4.3|Android 4.4|Android 5|Android 6|Android 7|Android 8|Android 9|Android 10)/i))||isIPad) {
  routes = routesM
}  else {
  routes = routesP
}





const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router