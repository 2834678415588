import Vue from 'vue'
import Vuex from 'vuex'
const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : 'https://api.whis.live'
Vue.use(Vuex)
// 开发环境:https://test.whis.live
// 正式: https://api.whis.live
// 预发布 https://pre.whis.live
export default new Vuex.Store({
  state: {
    url: baseUrl,
    Lan: '',
    token: '',
    lang: '',
    newLang: '',
    userInfo:''
    
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {}
})