// // rem等比适配配置文件
// // 基准大小
// const baseSize = 16
//     // 设置 rem 函数
// function setRem() {
//     const scale = document.documentElement.clientWidth / 1920
//         // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
//     document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
// }
// // 初始化
// setRem()
//     // 改变窗口大小时重新设置 rem
// window.onresize = function() {
//     setRem()
// }
// rem.js

// const baseSize = 16;

// function setRem() {
//     const screenWidth = document.documentElement.clientWidth;
//     const isMobile = screenWidth <= 768; // 假设小于等于 768px 的宽度为移动端

//     const scale = isMobile ? screenWidth / 375 : screenWidth / 1920;
//     document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
// }

// setRem();

// window.onresize = function () {
//     setRem();
// };
// |Macintosh
let ua = navigator.userAgent;
let isSafari = ua.indexOf("Safari") != -1 && ua.indexOf("Version") != -1;
let isIphone = ua.indexOf("iPhone") != -1 && ua.indexOf("Version") != -1
let isIPad = isSafari && !isIphone && 'ontouchend' in document;
if ((navigator.userAgent.match(/(iPhone|iPod|iPad|Android|Mobile|webOS|BlackBerry|IEMobile|Opera Mini|Windows Phone|Symbian|Kindle|Tablet|KFAPWI|Silk|Android 2.3|Android 4.0|Android 4.1|Android 4.2|Android 4.3|Android 4.4|Android 5|Android 6|Android 7|Android 8|Android 9|Android 10)/i)) || isIPad) {
    // const screenWidth = document.documentElement.clientWidth;
    //  if (screenWidth > 1000) {
    //     baseSize = 20; 
    // } else if (screenWidth > 770) {
    //     baseSize = 18; 
    // }

    function setRem() {
        const screenWidth = document.documentElement.clientWidth;
        const scale = screenWidth / 375;
        let baseSize = 16;
        if (screenWidth > 1079) {
            baseSize = 24;
        } else if (screenWidth > 1023) {
            baseSize = 21.5;
        } else if (screenWidth >= 820) {
            baseSize = 17.5;
        } else if (screenWidth > 770) {
            baseSize = 17;
        }
        console.log(baseSize);
        document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
    }
} else {
    function setRem() {
        console.log(2);
    }
}

setRem();

window.onresize = function () {
    setRem();
};