import Vue from 'vue';
import {Picker ,PullRefresh ,Popup ,ImagePreview,Uploader ,Dialog ,List,Sticky ,Overlay ,Loading , Button,NavBar,Form,Field,Notify,Tabbar,TabbarItem,Swipe, SwipeItem, ActionSheet,Cell,Toast  } from 'vant';
Vue.use(List);
Vue.use(Sticky);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Field);
Vue.use(Notify);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(ActionSheet);
Vue.use(Cell); 
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Dialog);
Vue.use(Uploader);
Vue.use(ImagePreview);
Vue.use(PullRefresh);
Vue.use(Picker);
Vue.use(Popup);





