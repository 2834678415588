const lang = {
  'translations': {
    'SelectLanguage': 'Lựa chọn ngôn ngữ',
    'Cancel': 'Hủy bỏ'
  },
  ljxz: 'Tải xuống ngay',
  index: 'Trang chủ',
  gyuwm: 'Về chúng tôi',
  qianbao: 'Ví',
  // placeholder: 'login',
  wename: 'Lắng nghe tiếng nói trong lòng của bạn',
  prtiao: 'Điều khoản dịch vụ',
  yinsi: 'Chính sách bảo mật',
  guanyu: 'Giới thiệu về Whis',
  shenyin: 'Ứng dụng đồng hành với đa giọng nói, kiên trì để tạo ra nhiều giá trị giao tiếp bằng tiếng nói',
  chuanzao: 'để cung cấp cho người dùng những trải nghiệm đa dạng và thú vị ví dụ như trò chuyện bằng âm thanh, tương tác trong phòng, giao lưu âm nhạc v. v',
  dongni: 'Kết bạn bằng giọng nói, để những người hiểu bạn đồng hành với bạn',
  lianxi: 'Liên hệ chúng tôi',
  kefu: 'Tài khoản CSKH',
  lxiren: 'Người liên hệ: CSKH',
  youxaing: 'Email: whis.cskh@gmail.com',
  dibu1: 'BLAGOL TECHNOLOGY CO.，LIMITED',
  dibu2: 'Rooms 1318-19,13/F,Hollywood Plaza,610Nathan Road, Mongkok, Kowloon, HongKong.',
  loLan_1: 'Đăng nhập nhanh',
  loLan_2: 'Để bảo vệ tính bảo mật cho tài khoản của bạn, vui lòng xác minh danh tính của bạn trước khi nạp tiền.',
  loLan_3: 'Đăng nhập được ủy quyền của App',
  loLan_4: 'Đăng nhập bằng ID Whis', 
  loLan_5: 'Đăng nhập bằng ID',
  loLan_6: 'ID Whis',
  loLan_7: 'Vui lòng nhập ID Whis của bạn',
  loLan_8: 'Mã xác nhận',
  loLan_9: 'Mã xác nhận',
  loLan_10: 'Gửi',
  loLan_11: 'Không cần đăng nhập trong vòng 7 ngày',
  loLan_12: 'Đăng nhập',
  loLan_13: 'Cách xác minh>',
  loLan_14: 'Gửi lại',
  loLan_15: 'Cách xác minh',
  loLan_16: 'giây',
  popLan_1: 'Trang chủ',
  popLan_2: 'Xác minh danh tính',
  popLan_3: 'Đang được xem xét',
  popLan_4: 'Đã xác minh danh tính',
  popLan_5: 'Ví',
  popLan_6: 'Lì xì',
  popLan_7: 'Về chúng tôi',
  popLan_8: 'Bạn có chắc chắn bạn đăng xuất tài khoản?',
  popLan_9: 'Đăng xuất',
  popLan_10: 'Hủy bỏ',
  popLan_11: 'Email (Khu vực tiếng Anh)',
  popLan_12: 'Email (Khu vực Việt Nam)',
  popLan_13: 'Đăng xuất',
  popLan_14: 'Thao tác thành công',
  popLan_15: 'Quốc gia/ khu vực này hiện chưa có dịch vụ',
  waLan_1: 'Ví',
  waLan_2: 'Tiền xu',
  waLan_3: 'Nạp tiền',
  waLan_4: 'Độ thu hút',
  waLan_5: 'Giao dịch',
  waLan_6: 'Rút tiền',
  waLan_7: 'Khóa',
  waLan_8: '——Chỉ hiển thị hóa đơn trong vòng 12 tháng trước——',
  waLan_9: 'Sau khi đăng ký rút tiền được xem xét, lịch sử rút tiền thành công sẽ được hiển thị tại đây',
  waLan_10: 'Trong quá trình xem xét đăng ký rút tiền, độ thu hút sẽ được hiển thị là bị khóa tại đây',
  rcLan_1: 'Chọn phương thức nạp',
  rclan_99: 'Chọn quốc gia',
  rclan_15: 'Hủy bỏ',
  rcLan_2: 'Việt Nam',
  rcLan_3: 'Mỹ',
  rcLan_4: 'Nigeria',
  rcLan_5: 'Philippin',
  rclan_999: 'Nam Phi',
  rcLan_6: 'Đại lý chính thức',
  rcLan_7: 'Chọn số tiền nạp',
  rcLan_8: 'Số tiền nạp',
  rcLan_9: 'Thanh toán',
  rcLan_10: 'Nhu cầu nạp tiền lớn của bạn có thể được đáp ứng dễ dàng thông qua dịch vụ nạp tiền qua Đại lý của App. Liên hệ với tài khoản Đại lý chính thức trong Ứng dụng để hỏi chi tiết và hoàn tất thao tác nạp tiền. Đại lý sẽ nạp tiền vào tài khoản của bạn bằng cách gửi lì xì trên App',
  rcLan_11: 'Danh sách tài khoản Đại lý',
  rcLan_12: 'Sau đây là danh sách các tài khoản Đại lý đã ký hợp đồng và có đủ điều kiện nạp tiền chính thức. Các bạn hãy cẩn thận để nhận biết và tránh trường hợp bị lừa đảo. Nếu bạn gặp vấn đề như số tiền nạp không đến tài khoản của mình, vui lòng giữ ảnh chụp màn hình để làm bằng chứng và liên hệ với CSKH online trong Ứng dụng để khiếu nại.',
  rcLan_13: 'Liên hệ',
  rcLan_16: 'Tài khoản đại lý',
  gjLan_1: 'Anh',
  gjLan_2: 'Ca-na-đa',
  gjLan_3: 'Niu Di-lơn',
  gjLan_4: 'Úc',
  chLan_1: 'Độ thu hút',
  chLan_2: 'Địa chỉ email Payonner (không thể bỏ liên kết sau khi liên kết)',
  chLan_3: 'Đăng ký >',
  chLan_4: 'Nhập email Payoneer',
  chLan_5: 'Liên kết',
  chLan_6: 'Đã liên kết',
  chLan_7: 'Chuyển đổi độ thu hút',
  chLan_8: 'Phí rút tiền: 1.0%',
  chLan_9: 'Vui lòng điền giá trị',
  chLan_10: 'Số dư',
  chLan_11: 'Chuyển đổi hết',
  chLan_12: 'Nhắc nhở:',
  chLan_13: '1. Nếu địa chỉ email Payoneer bạn điền không chính xác, quá trình chuyển tiền sẽ không thành công',
  chLan_14: '2. Độ thu hút mỗi lần chuyển đổi tối thiểu là 36000',
  chLan_15: '3. Đăng ký rút tiền chỉ có thể thực hiện từ Thứ Ba đến Thứ Tư (GMT+7) và mỗi tài khoản rút tiền 01 lần/tuần',
  chLan_16: '4. Sau khi đăng ký rút tiền, độ thu hút của bạn sẽ bị khóa cho đến khi quá trình xem xét hoàn tất',
  chLan_17: '5. Tài khoản Payoneer của bạn cần có số dư ít nhất $50 mới có thể rút tiền về thẻ ngân hàng',
  chLan_18: 'Rút tiền',
  chLan_19: 'Bạn có chắc chắn muốn liên kết email này không?',
  chLan_20: 'Vui lòng đảm bảo địa chỉ email bạn điền khớp với địa chỉ email trên Payoneer',
  chLan_21: 'Nếu địa chỉ email bạn điền không chính xác thì quá trình chuyển đổi sẽ không thành công',
  chLan_22: 'Liên kết',
  chLan_23: 'Tiếp tục',
  chLan_24: 'Quay lại sửa đổi',
  chLan_25: ' Mỗi 700 độ hút thu có giá trị 1 USD',
  chLan_26: ' Phí rút tiền: 1.0%',
  chLan_27: ' Đăng ký đã được gửi thành công',
  chLan_28: ' Đang được xem xét (dự kiến 10 ngày làm việc)',
  chLan_29: ' Đến tài khoản Payoneer',
  chLan_30: ' Trở về ví',
  chLan_31: ' Khoảng xx USD',
  chLan_32: ' khoảng',
  chLan_33: ' USD',
  chLan_34: 'Chuyển đổi độ thu hút',
  envLan_1: 'Lì xì',
  envLan_2: 'Tiền xu',
  envLan_3: 'ID của người nhận',
  envLan_4: 'Vui lòng nhập ID',
  envLan_5: 'Số tiền lì xì(tiền xu)',
  envLan_6: 'Vui lòng nhập số tiền',
  envLan_7: 'Gửi',
  envLan_8: 'Bạn có chắc chắn muốn gửi lì xì không?',
  envLan_9: 'ID',
  envLan_10: 'Số lượng',
  envLan_11: 'Thời gian truy cập gần đây nhất của tài khoản',
  envLan_12: 'Hủy bỏ',
  envLan_13: 'Xác nhận',
  envLan_14: 'Online',
  envLan_15: 'phút trước',
  envLan_16: 'giờ trước',
  envLan_17: 'ngày trước',
  envLan_18: 'ngày',
  icLan_1: 'Xác minh danh tính',
  icLan_2: 'Điền thông tin',
  icLan_3: 'Đã đăng ký, vui lòng chờ kết quả xem xét',
  icLan_4: 'Xác minh không thành công',
  icLan_5: 'Lý do thất bại：',
  icLan_6: 'Xác minh thành công',
  icLan_7: 'Tìm kiếm tài khoản dưới tên của bạn',
  icLan_8: 'Vui lòng điền thông tin thật của bạn, thông tin xác minh danh tính được liên kết với số tài khoản ngân hàng. Hãy điền tên thật và số giấy tờ chứng minh của bạn và phải trùng với tên tài khoản thẻ ngân hàng để rút tiền, nếu không việc rút tiền sẽ không thể thực hiện được',
  icLan_9: 'Thông tin cá nhân được chúng tôi thu thập cam kết sẽ không chia sẻ với bất kỳ bên thứ ba nào',
  icLan_10: 'Họ và tên',
  icLan_11: 'Vui lòng nhập họ và tên:',
  icLan_12: 'Số giấy tờ chứng minh',
  icLan_13: 'Vui lòng nhập số giấy tờ chứng minh',
  icLan_14: 'Hình giấy tờ chứng minh',
  icLan_15: 'Mặt trước giấy tờ chứng minh',
  icLan_16: 'Mặt sau giẩy tờ chứng minh',
  icLan_17: 'Gửi',
  icLan_18: 'Xác minh lại danh tính',
  icLan_19: 'Nhắc nhở',
  icLan_20: 'Vui lòng kiểm tra xem thông tin giấy tờ chứng minh của bạn có đúng và được điền chính xác hay không',
  icLan_21: 'Họ và tên',
  icLan_22: 'Số giấy tờ chứng minh',
  icLan_23: 'Hủy bỏ',
  icLan_24: 'Xác nhận',
  icLan_25: 'Tìm kiếm tài khoản dưới tên của bạn',
  toastLan_1: 'Thao tác thành công',
  toastLan_2: 'Định dạng không chính xác, vui lòng tải lên ở định dạng PNG, JPG hoặc JPEG',
  toastLan_3: 'Vui lòng tải lên hình ảnh nhỏ hơn 20M',
  toastLan_4: 'Đã sao chép UID thành công',
  toastLan_5: 'Vui lòng qua App để liên hệ với chúng tôi',
  toastLan_6: 'Hãy tải APP trước ',
  toastLan_7: 'Hãy mở trang web chính thức của app để nạp tiền',
  toastLan_8: '(Chỉ phiên bản 10080 trở lên mới có thể cấp phép bình thường)',
  toastLan_9: 'Cao',
  toastLan_10: 'Trung bình',
  toastLan_11: 'Thấp',
  toastLan_12:' Chưa có dữ liệu',
  toastLan_13:'Khuyến mãi',

};
export default lang;