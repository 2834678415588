const lang = {
  "translations": {
    "SelectLanguage": "选择语言",
    "Cancel": "取消"
  },
  ljxz: '立即下载',
  index: '首页',
  gyuwm: '关于我们',
  qianbao: '钱包',
  // placeholder: 'login',
  wename: '倾 听 内 心 的 声 音',
  prtiao: '服务条款',
  yinsi: '隐私政策',
  guanyu: '关于Whis',
  shenyin: "一个多元声音陪伴平台，坚持以声音创造更多社交价值",
  chuanzao: "为用户提供声音探索、房间互动、连麦陪伴、音乐交流等多元声音趣味体验",
  dongni: '用声音交友，让懂你的人陪伴你',
  lianxi: '联系我们',
  kefu: "客服账号",
  lxiren: '联系人: CSKH',
  youxaing: "邮箱: whis.cskh@gmail.com",
  dibu1: 'BLAGOL TECHNOLOGY CO.，LIMITED',
  dibu2: 'Rooms 1318-19,13/F,Hollywood Plaza,610Nathan Road, Mongkok, Kowloon, HongKong.',
  loLan_1: '快速登录',
  loLan_2: '为了保护你的账号安全，充值前请进行身份验证。',
  loLan_3: 'App授权登录',
  loLan_4: 'Whis ID登录',
  loLan_5: 'ID登录',
  loLan_6: 'Whis ID',
  loLan_7: '请输入你的Whis ID',
  loLan_8: '验证码',
  loLan_9: '请输入你的验证码',
  loLan_10: '发送',
  loLan_11: '7天内免登录',
  loLan_12: '登录',
  loLan_13: '如何验证 >',
  loLan_14: '重新发送',
  loLan_15: '如何验证',
  loLan_16: '秒',
  popLan_1: '首页',
  popLan_2: '身份认证',
  popLan_3: '审核中',
  popLan_4: '已认证',
  popLan_5: '钱包',
  popLan_6: '红包',
  popLan_7: '关于我们',
  popLan_8: '确定退出登录吗？',
  popLan_9: '退出',
  popLan_10: '取消',
  popLan_11: '邮箱 (英语区)',
  popLan_12: '邮箱 (越南语区)',
  popLan_13: '退出登录',
  popLan_14: '操作成功',
  popLan_15: '此国家/地区暂未开放服务',
  waLan_1: '钱包',
  waLan_2: '金币',
  waLan_3: '充值',
  waLan_4: '魅力值',
  waLan_5: '交易',
  waLan_6: '提现',
  waLan_7: '锁定',
  waLan_8: '—— 仅展示前12个月账单记录 ——',
  waLan_9: '提现审核通过后，成功提现记录将在此处显示',
  waLan_10: '提现审核过程中，魅力值将在此处显示为锁定状态',
  rcLan_1: '选择充值方式',
  rclan_99: '选择国家',
  rclan_15: '取消',
  rcLan_2: '越南',
  rcLan_3: '美国',
  rcLan_4: '尼日利亚',
  rcLan_5: '菲律宾',
  rclan_999: '南非',
  rcLan_6: '官方代充',
  rcLan_7: '选择充值金额',
  rcLan_8: '充值金额',
  rcLan_9: '支付',
  rcLan_10: '您的大额充值需求，可通过官方代充服务轻松满足。在App内联系官方代充账号，咨询详情并完成储值操作。代充账号将通过发送平台红包的方式，为您的账户充值。',
  rcLan_11: '官方代充名单',
  rcLan_12: '以下为已签约并拥有官方代充资质的账号列表。请注意辨别，避免受骗。如遇代充金额未到账等问题，请保留相关证据截图，并在App内联系在线客服进行投诉。',
  rcLan_13: '联系',
  rcLan_16: '代充账号',
  gjLan_1: '英国',
  gjLan_2: '加拿大',
  gjLan_3: '新西兰',
  gjLan_4: '澳大利亚',
  chLan_1: '魅力值',
  chLan_2: 'Payonner邮箱（绑定后不可解绑）',
  chLan_3: '注册 >',
  chLan_4: '请填写Payoneer邮箱',
  chLan_5: '绑定',
  chLan_6: '已绑定',
  chLan_7: '提现魅力值',
  chLan_8: '提现手续费: 1.0%',
  chLan_9: '请填写数值',
  chLan_10: '可提现魅力值',
  chLan_11: '全部提现',
  chLan_12: '提示：',
  chLan_13: '1.若您填写的Payoneer邮箱有误，转账将失败',
  chLan_14: '2.每次提现最低魅力值为36000',
  chLan_15: '3.提现申请仅限于周二至周三（GMT+7）进行，每个账号每周限提现1次',
  chLan_16: '4.提出提现申请后，您对应的魅力值将被锁定，直至审核完成',
  chLan_17: '5.您的Payoneer账户需要有至少50美元余额才能提现至银行卡',
  chLan_18: '提现',
  chLan_19: '确定绑定此邮箱吗',
  chLan_20: '确保填写的邮箱与Payoneer上的邮箱一致',
  chLan_21: '邮箱填写错误将导致转账失败',
  chLan_22: '绑定',
  chLan_23: '继续绑定',
  chLan_24: '返回修改',
  chLan_25: ' 每700魅力值价值1美元',
  chLan_26: ' 提现手续费为1.0%',
  chLan_27: ' 申请提交成功',
  chLan_28: ' 审核中（预计10个工作日）',
  chLan_29: ' Payoneer到账',
  chLan_30: ' 返回钱包',
  chLan_31: ' 约xx美金',
  chLan_32: ' 约',
  chLan_33: ' 美金',
  chLan_34: '魅力提现',
  envLan_1: '红包',
  envLan_2: '金币',
  envLan_3: '接收人ID',
  envLan_4: '请输入ID',
  envLan_5: '红包金额（金币）',
  envLan_6: '请输入金额',
  envLan_7: '发送',
  envLan_8: '确认发红包吗',
  envLan_9: 'ID',
  envLan_10: '金额',
  envLan_11: '账号最后活跃时间',
  envLan_12: '取消',
  envLan_13: '确认',
  envLan_14: '在线',
  envLan_15: '分钟之前',
  envLan_16: '小时前',
  envLan_17: '天前',
  envLan_18: '日',
  icLan_1: '身份认证',
  icLan_2: '填写信息',
  icLan_3: '已申请，等待审核结果...',
  icLan_4: '认证失败',
  icLan_5: '失败原因：',
  icLan_6: '认证成功',
  icLan_7: '查询名下账号',
  icLan_8: '请填写本人真实信息，身份认证信息与银行账号关联，请务必填写本人真实姓名和身份证号，且姓名必须与提现银行卡户名一致，否则无法提现',
  icLan_9: '平台通过本次验证所收集的个人信息不会与任何第三方共享',
  icLan_10: '真实姓名',
  icLan_11: '请输入真实姓名',
  icLan_12: '身份证号',
  icLan_13: '请输入身份证号',
  icLan_14: '身份证照片',
  icLan_15: '身份证正面',
  icLan_16: '身份证反面',
  icLan_17: '提交',
  icLan_18: '重新认证',
  icLan_19: '温馨提示',
  icLan_20: '请检查您的身份证信息是否是本人真实信息且填写无误',
  icLan_21: '姓名',
  icLan_22: '证件号码',
  icLan_23: '取消',
  icLan_24: '确认',
  icLan_25: '查询名下账号',
  toastLan_1: '操作成功',
  toastLan_2: '格式不正确，请使用 PNG、JPG 或 JPEG 格式上传',
  toastLan_3: '请上传小于 20M 的图片',
  toastLan_4: '已成功复制 UID',
  toastLan_5: '请前往App进行联系',
  toastLan_6: '请先安装APP ',
  toastLan_7: '请在外部浏览器中打开',
  toastLan_8: '（仅10800版本以上，可正常授权）',
  toastLan_9: '库存充足',
  toastLan_10: '库存中等',
  toastLan_11: '库存不足',
  toastLan_12: '暂无数据',
  toastLan_13: '专属特惠',

  
}

export default lang