import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/util/pxrem.js'

import "@/assets/css/reset.css"

// import vant from './util/vant'
import '@/util/vant.js'
import Loading from '@/components/loading.vue'

// import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locale/langs/en';
import zh from './locale/langs/zh';
import vi from './locale/langs/vi';

// 默认语言
const currentUrl = window.location.href;
const urlParams = new URLSearchParams(currentUrl.split('?')[1]);
const lang = urlParams.get('lang');
let defaultLocale;
var userLanguage = navigator.language || navigator.userLanguage;
let prefer = localStorage.getItem('preferredLanguage')
if (lang) {
  defaultLocale=lang=='1'?'zh-CN':lang=='2'?'vi':'en'
} else {
  if (prefer) {
    store.state.Lan = localStorage.getItem('switchLanguage')
    defaultLocale = localStorage.getItem('preferredLanguage')
    console.log(2);
  } else {
    if (userLanguage.startsWith("vi")) {
      localStorage.setItem("preferredLanguage", "vi");
      localStorage.setItem("switchLanguage", "Tiếng Việt");
      defaultLocale = 'vi'
      store.state.Lan = "Tiếng Việt"
    } else if (userLanguage.startsWith("zh")) {
      localStorage.setItem("preferredLanguage", "zh-CN");
      localStorage.setItem("switchLanguage", "中文(简体)");
      defaultLocale = 'zh-CN'
      store.state.Lan = "中文(简体)"
    } else {
      localStorage.setItem("preferredLanguage", "en");
      localStorage.setItem("switchLanguage", "English");
      defaultLocale = 'en'
      store.state.Lan = "English"
    }
  }

}
// 尝试从本地存储中获取用户选择的语言，如果没有则使用默认语言
const savedLanguage = defaultLocale
// 在 main.js 中导入多语言插件使用
// import VueI18n from 'vue-i18n'
Vue.component('Loading', Loading)
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: savedLanguage,
  messages: {
    en,
    'zh-CN': zh,
    vi,
  },
});

router.beforeEach((to, from, next) => {
  // 替换title， 提供给客户端
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})


Vue.config.productionTip = false



new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')