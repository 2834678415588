const lang = {
  'translations': {
    'SelectLanguage': 'Language',
    'Cancel': 'Cancel'
  },
  ljxz: 'Download',
  index: 'Homepage',
  gyuwm: 'About us',
  qianbao: 'Wallet',
  // placeholder: 'login',
  wename: 'Embrace the whispers of your inner self.',
  prtiao: 'Terms of Service',
  yinsi: 'Privacy Policy',
  guanyu: 'About Whis',
  shenyin: 'A voice platform where you meet friends from various countries, committed to creating vibrant social experiences through the power of sound.',
  chuanzao: 'Whis aims to offer users diverse and enjoyable sound experiences, including voice exploration, room interactions, live conversations, and music sharing.',
  dongni: 'Connect through the essence of voice, and let those who understand you be your companions.',
  lianxi: 'Contact us',
  kefu: 'Customer service account',
  lxiren: 'Contact: CS',
  youxaing: 'Email: whis.cshk@gmail.com',
  dibu1: 'BLAGOL TECHNOLOGY CO.，LIMITED',
  dibu2: 'Rooms 1318-19,13/F,Hollywood Plaza,610Nathan Road, Mongkok, Kowloon, HongKong.',
  loLan_1: 'Quick Login',
  loLan_2: 'For the security of your account, please undergo identity verification before recharging.',
  loLan_3: 'App authorization login',
  loLan_4: 'Whis ID login',
  loLan_5: 'ID Log in',
  loLan_6: 'Whis ID',
  loLan_7: 'Please enter your Whis ID',
  loLan_8: 'Verification Code',
  loLan_9: 'Verification code required',
  loLan_10: 'Send',
  loLan_11: 'No login required within 7 days',
  loLan_12: 'Log In',
  loLan_13: 'How to verify >',
  loLan_14: 'Resend',
  loLan_15: 'How to verify',
  loLan_16: 'Second',
  popLan_1: 'Homepage',
  popLan_2: 'Identity Verification',
  popLan_3: 'Reviewing',
  popLan_4: 'Verified',
  popLan_5: 'Wallet',
  popLan_6: 'Resell Package',
  popLan_7: 'About us',
  popLan_8: 'Confirm logout?',
  popLan_9: 'Quit',
  popLan_10: 'Cancel',
  popLan_11: 'E-mail (English Server)',
  popLan_12: 'E-mail(Vietnam Server)',
  popLan_13: 'Log out',
  popLan_14: 'Success',
  popLan_15: 'Unsupported Region',
  waLan_1: 'Wallet',
  waLan_2: 'Coin',
  waLan_3: 'Top Up',
  waLan_4: 'Charm Value',
  waLan_5: 'Transaction',
  waLan_6: 'Withdrawal',
  waLan_7: 'Lock',
  waLan_8: '——Displaying only the first 12 months of billing records——',
  waLan_9: 'After withdrawal approval, successful withdrawal records will be displayed here',
  waLan_10: 'During the withdrawal review process, Charm will be displayed here in a locked state',
  rcLan_1: 'Top Up Method',
  rclan_99: 'Country',
  rclan_15: 'Cancel',
  rcLan_2: 'Vietnam',
  rcLan_3: 'US',
  rcLan_4: 'Nigeria',
  rcLan_5: 'Philippine',
  rclan_999: 'South Africa',
  rcLan_6: 'Coin Seller',
  rcLan_7: 'Top up Amount',
  rcLan_8: 'Top UP Amount',
  rcLan_9: 'Pay',
  rcLan_10: 'Coin sellers in-app offer the best deal for large-amount recharge. Contact the official coin seller in the app and complete the deposit operation. The coin seller account will top up your account by sending resell package.',
  rcLan_11: 'Coin seller list',
  rcLan_12: 'The following is a list of accounts that have signed up and have official coin seller credentials. Please pay attention to identify and avoid being cheated. If you encounter any problems such as charging amount not arriving at the account, please keep the relevant evidence screenshots and contact online customer service in the app.',
  rcLan_13: 'Contact',
  rcLan_16: 'Coin Seller Account',
  gjLan_1: 'UK',
  gjLan_2: 'Canada',
  gjLan_3: 'New Zealand',
  gjLan_4: 'Australia',
  chLan_1: 'Charm Value',
  chLan_2: 'Payoneer Email (Non-detachable once linked)',
  chLan_3: 'Sign in >',
  chLan_4: 'Please bind your Payoneer email first',
  chLan_5: 'Bind',
  chLan_6: 'Bound',
  chLan_7: 'Withdraw Charm Value',
  chLan_8: 'Withdrawal fee: 1.0%',
  chLan_9: 'Please fill in numbers',
  chLan_10: 'Charm Value',
  chLan_11: 'Withdraw all',
  chLan_12: 'Tip：',
  chLan_13: '1.If the Payoneer email you provided is incorrect, the transfer will fail',
  chLan_14: '2.Minimum charm value of 36000 per withdrawal',
  chLan_15: '3.Withdrawal requests are only allowed on Tuesdays and Wednesdays (GMT+8). Each account is limited to one withdrawal per week',
  chLan_16: '4.After submitting a withdrawal request, your corresponding charm points will be locked until the review is completed',
  chLan_17: '5.You need to have a minimum balance of $50 in your Payoneer account in order to withdraw to your bank card',
  chLan_18: 'Withdrawal',
  chLan_19: 'Confirm to bind with this E-mail?',
  chLan_20: 'Make sure the email address you fill in is the same as the one on Payoneer',
  chLan_21: 'Incorrect email address will cause the transfer failed',
  chLan_22: 'Bind',
  chLan_23: 'Continue binding',
  chLan_24: 'Return to modify',
  chLan_25: ' Every 700 charm are worth 1 USD',
  chLan_26: ' Withdrawal fee: 1.0%',
  chLan_27: ' Application submitted successfully',
  chLan_28: 'Reviewing (Expected duration: 10 business days)',
  chLan_29: ' Payoneer credited',
  chLan_30: 'Return wallet',
  chLan_31: ' About xx USD',
  chLan_32: 'About',
  chLan_33: ' Dollar',
  chLan_34: 'Charm Withdraw',
  envLan_1: 'Resell Package',
  envLan_2: 'Coin',
  envLan_3: 'Recipient ID',
  envLan_4: 'Please enter ID',
  envLan_5: 'Package Amount (Coin)',
  envLan_6: 'Please enter the amount',
  envLan_7: 'Send',
  envLan_8: 'Confirm to send resell package?',
  envLan_9: 'ID',
  envLan_10: 'Amount',
  envLan_11: 'Last active time of the account',
  envLan_12: 'Cancel',
  envLan_13: 'Confirm',
  envLan_14: 'Online',
  envLan_15: 'mins ago',
  envLan_16: 'hours ago',
  envLan_17: 'days ago',
  envLan_18: 'Daily',
  icLan_1: 'Identity Verification',
  icLan_2: 'Fill in the information',
  icLan_3: 'Application submitted, awaiting review result...',
  icLan_4: 'Authentication failed',
  icLan_5: 'Failed reason：',
  icLan_6: 'Authentication successful',
  icLan_7: 'Check your account',
  icLan_8: 'Please provide your real information. Identity verification is linked to your bank account. Make sure to enter your real name and ID number, and the name must match the account holder\'s name on the withdrawal bank card; otherwise, withdrawal might be failed.',
  icLan_9: 'The platform will not share the personal information collected during this verification process with any third party',
  icLan_10: 'Real name',
  icLan_11: 'Please enter real name',
  icLan_12: 'ID card number',
  icLan_13: 'Please enter ID number',
  icLan_14: 'ID card image',
  icLan_15: 'Front side of the ID card',
  icLan_16: 'Back side of the ID card',
  icLan_17: 'Submit',
  icLan_18: 'Reauthenticate',
  icLan_19: 'Reminder',
  icLan_20: 'Please check if your ID information is accurate and filled in correctly with your real information',
  icLan_21: 'Name',
  icLan_22: 'Identification number',
  icLan_23: 'Cancel',
  icLan_24: 'Confirm',
  icLan_25: 'Check your account',
  toastLan_1: 'Success',
  toastLan_2: 'The format is incorrect. Please upload in PNG, JPG, or JPEG format',
  toastLan_3: 'Please upload an image smaller than 20 MB',
  toastLan_4: 'UID Copied ',
  toastLan_5: 'Please go to the app for contact',
  toastLan_6: 'Please install app first ',
  toastLan_7: 'Please open it in external browser',
  toastLan_8: '(Only version 10800 and above can be authorized)',
  toastLan_9: 'Ample Stock',
  toastLan_10: 'Moderate Stock',
  toastLan_11: 'Low Stock',
  toastLan_12:'No data',
  toastLan_13:'VIP Discount',

};
export default lang;