<template>
  <div class="loading">
    <iframe ref="frame" width="100%" height="100%" :src="htmlFileUrl"></iframe>
  </div>
</template>
  
<script>
export default {
  name: "loading",
  data() {
    return {
      // htmlFileUrl:process.env.NODE_ENV === 'development' ? '/loading/demo/loading.html' : '/whisAuthority/loading/demo/loading.html',
      htmlFileUrl: "/loading/demo/loading.html",
    };
  },
};
</script>


<style>
.loading {
  position: fixed;
  left: 100px;
  width: 50%;
  height: 50%;
  z-index: 10;
}
</style>